// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadastrar-local-js": () => import("./../src/pages/cadastrar-local.js" /* webpackChunkName: "component---src-pages-cadastrar-local-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-local-js": () => import("./../src/templates/local.js" /* webpackChunkName: "component---src-templates-local-js" */)
}

